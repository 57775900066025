.asset-reliance-chart-container {
  height: 248px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;

  .asset-reliance-barchart-row {
    display: flex;
    flex-flow: nowrap !important;

    &.barchart-row-underlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      right: 15px;
    }

    .barchart-container {
      height: 100%;

      .stacked-bar-chart {
        display: flex;
        justify-content: center;
        height: 100%;
        flex-grow: 0;
        margin: 0 12px;
      }
    }

    &.compare-plans-barchart-row-underlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      right: 15px;
      width: 33.4%;
    }

    .compareplan_barchart-container {
      height: 100%;

      .stacked-bar-chart {
        display: flex;
        justify-content: center;
        height: 100%;
        flex-grow: 0;
        margin-right: 120px;
      }

      .compare-plans-asset-bar-chart {
        margin-left: 310px;
      }

      &__items {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 50px;
      }

      &__item {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 2px;
        transition: opacity 0.3s;

        &--inactive {
          opacity: 0.5;
        }
      }

      &__fill {
        position: relative;
        width: 100%;
        height: 100%;
      }

      &--selectable &__fill {
        cursor: pointer;
        outline: none;
      }
    }

  }
}

.asset-reliance-barchart-legend-label {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-left: 18px;
}