.asset-reliance-asset-line-container {
  display: inline-block;
  margin: auto;
  z-index: 1;
  left: 50%;
  transform: translate(-50%);
  background: $color-sufficiency-positive;
  border-radius: 32px;
  width: 12px;
}
.compare-plans-asset-line{
  display: inline-block;
  margin: auto;
  z-index: 1;
  left: 35%;
  transform: translate(-50%);
  background: $color-sufficiency-positive;
  border-radius: 32px;
  width: 12px;
}
.compare-plans-asset-line-stack-one{
  display: inline-block;
  margin: auto;
  z-index: 1;
  left: 90%;
  transform: translate(-50%);
  background: $color-sufficiency-positive;
  border-radius: 32px;
  width: 12px;
}