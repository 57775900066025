.compare-plans-content {
  margin-top: 44px;
  padding-left: 48px;
  padding-right: 48px;
  overflow-x: hidden;

  .main-container {
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }

  .compare-plans-sub-header {
    font-family: Roboto, serif;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 12px;

    &.sub-title {
      text-align: center;
      padding-top: 16px;
    }
  }

  .bar-chart-legend-container {
    display: flex;
    flex-wrap: wrap;
  }

  .asset_insufficiency_empty_state {
    text-align: center;
    margin-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
    font-size: 18px;
    font-weight: 300;
  }

  .asset-reliance-barchart-legend-label {
    padding-left: 0;
    font-weight: 500;
  }

  .compare-plans-dropdown {
    padding-top: 5px;

    .dropdown__panel {
      z-index: 10;
    }

    .dropdown__panel .dropdown__item {
      white-space: normal;
    }

    label {
      font-weight: 500;
      color: #767676;
    }
  }
}

.divider {
  margin-bottom: -24px;
  margin-left: -1px;
  width: 0;
  border-left: 1px solid $color-nt-grey-100;
}

.three-dots-menu {
  .icon {
    font-size: 18px;
    font-weight: 400;
    color: white;
    padding-right: 0px;

    &:hover {
      color: $color-aqua-800;
      box-shadow: none;
    }
  }
}

.page-action-menu-options {
  display: flex;
  justify-content: space-evenly;
}

.compare-plans-pill-container {
  margin-left: 8px;
  width: auto;
  height: auto;
  padding: 0 8px 0 8px;
  border-radius: 100px 100px 100px 100px;
  display: inline-block;
  justify-content: center;
  align-items: center;

  .pill_text {
    color: var(--Colors-Orange-800, #AC5300);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.pill-container-shortfall {
  border: 2px solid #FFEEDE;
  background-color: #FFEEDE;
}

.pill-container-excess {
  border: 2px solid #E2FCE3;
  background-color: #E2FCE3;
}

.pill-container-row {
  display: contents;
}

.rendered-value-compare-plans {
  text-align: center;
  width: auto;
  display: inline-block;
}

.compare-plans-empty-state-hr {
  margin-top: -10px;
  width: 223%;
  position: sticky;
}
.compare-plans-hr {
  margin-left: -15
}

.compare-plans-empty-state {
  text-align: center;
  display: flex;
  width: 100%;
  position: relative;
  top: -160px;
  font-family: Roboto;
  font-size: 18px;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  margin-left: 70px;
}